<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import ChartGraduation from '@/components/widgets/chart-graduation.vue';
import ChartPoints from "@/components/widgets/chart-points";

import { VueSvgGauge } from "vue-svg-gauge";

import moment from "moment";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartPoints,
    VueSvgGauge,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        prediction: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: 'inactive',

      permanent: {
        id: null,
        name: "-",
        image: null,
      },

      premiations: null,

      statData: null,
      wallets: null,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        sale: '0.00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: '0.00'
      },
    };
  },
  methods: {
    getMonthly() {
      api
        .get('report/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalPrediction() {
      api
        .get('report/bonus/prediction')
        .then(response => {
          if (response.data.status=='success') {
            this.total.prediction = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api.get("report/bonus/total").then((response) => {
        if (response.data.status == "success") {
          this.total.bonus = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: "center",
          icon: "package",
          title: "Plano",
          value: this.account.user.package,
        },
        {
          type: "center",
          icon: "monthly",
          title: "Status <sup>" + moment().format("M/YYYY") + "</sup>",
          value: this.monthly,
        },
        {
          type: "center",
          icon: "graduation",
          title: "Graduação",
          value: this.permanent.name,
        },
        {
          type: "center",
          icon: "prediction",
          title:
            "Previsão de Ganhos <sup>" + moment().format("M/YYYY") + "</sup>",
          value: this.total.prediction,
        },
        {
          type: "center",
          icon: "total",
          title: "Total de Bônus",
          value: this.total.bonus,
        },
        {
          type: "center",
          icon: "balance",
          title: "Saldo Disponível",
          value: this.total.balance,
        },
      ];
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status=='success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.sale = data.sale
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
    getGraduation() {
      api.get("report/graduation").then((response) => {
        if (response.data.status == "success") {
          this.permanent.id = response.data.current.id;
          this.permanent.name = response.data.current.name;
          this.permanent.image = response.data.current.image;
          this.getStatData();
        }
      });
    },
    getPremiations() {
      api.get("report/premiations").then((response) => {
        if (response.data.status === "success") {
          this.premiations = response.data;
        }
      });
    },
  },
  mounted() {
    this.getMonthly()
    this.getTotalPrediction()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getCart()
    this.getGraduation()
    this.getPremiations()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!
            </div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          {{ t("Referral link") }}
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="'https://app.zootybrasil.com.br/' + account.user.username">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://app.zootybrasil.com.br/' + account.user.username
            " v-on:click="$noty.success('O link foi copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="mb-0 align-items-center">Link da loja virtual</h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="'https://' + account.user.username + '.lojazootybrasil.com.br/'">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://' + account.user.username + '.lojazootybrasil.com.br/'
            " v-on:click="$noty.show('O link foi copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="premiations && premiations.current" class="premiations d-flex" :class="premiations.current.slug">
      <div class="info align-self-center">
        <vue-svg-gauge class="chart" :start-angle="-110" :end-angle="110" :value="parseFloat(premiations.current.percent)" :separator-step="0" :min="Number(0)" :max="Number(100)" gauge-color="#000"
          :scale-interval="0" />
        <div class="points text-white">
          <h1 class="mb-0 text-white">{{ premiations.total | points }}</h1>
          <p>PONTOS<br />ACUMULADOS</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center"><i class="bx bx-line-chart mr-1"></i> Graduação Mensal</h5>
            <ChartGraduation />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center"><i class="bx bx-line-chart mr-1"></i> {{ t('Total Points') }}</h5>
            <ChartPoints></ChartPoints>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.premiations {
  display: block;
  position: relative;
  background-color: #999;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin-bottom: 30px;
  width: 100%;
  height: 400px;
}
.premiations.cruzeiro {
  /**background-image: url("~@/assets/images/premiations/cruzeiro.png");**/
}
.premiations.viagem {
  /**background-image: url("~@/assets/images/premiations/viagem.png");**/
}
.premiations.carro {
  /**background-image: url("~@/assets/images/premiations/carro.png");**/
}
.premiations .info {
  position: absolute;
  right: 100px;
}
.premiations .info .chart {
  height: 220px;
}
.premiations .info .points {
  position: absolute;
  top: 120px;
  width: 100%;
  text-align: center;
}
.premiations .info .points p {
  line-height: 13px;
}

@media (max-width: 575px) {
  .premiations {
    background-size: 120% 100%;
    height: 300px;
    justify-content: center;
  }
  .premiations .info {
    right: auto;
  }
  .premiations .info .chart {
    height: 190px;
  }

  .premiations .info .points {
    top: 90px;
  }
}
</style>
